﻿//const { forEach } = require("../lib/fontawesome/js/v4-shims");

 
const $fileWrapper = $('.files');
const $fileInput = $('.js-input-files');
const $inputtedFilesPlacement = $('.js-show-inputted-files');
const $inputtedFileWrapper = $('.inputted-files');
const $convertButton = $('.js-convert-files-button'); 
const $clearInputtedFilesBtn = $('.js-clear-inputted-files');
const $inputLimit = $('.js-input-limit');
const $loginLink = $('a#login');
const ONE_FILE_LIMIT = 1 * 1024 * 1024; //1 MB in bytes (binary)
const $accountButton = $('.account-actions__button');
const $accountMenu = $('.account-actions__menu');

var cookiesModal = document.querySelector('#cookiesModal');
var closeCookiesModalBtn = document.querySelector('#closeCookiesModalBtn');

let isError = false;
let isLargeFile = false;
let inputtedFiles, converterResponse;
let pendingPageRefreshAfterSuccessSignin = false;
let accountMenuVisibility = false;

//Event listeners
$('a').attr('tabindex', '-1');
$fileInput.on('change', function (event) {
    inputAlertShow(false);
    isError = false;
    isLargeFile = false;
    let inputedFilesSize;
    inputtedFiles = event.target.files;
    for (let i = 0; i < inputtedFiles.length; i++) {
        if (i === 0) {
            inputedFilesSize = inputtedFiles[i].size;
        } else {
            inputedFilesSize += inputtedFiles[i].size;
        }

        if (inputtedFiles[i].size > ONE_FILE_LIMIT) {
            isLargeFile = true;
        }

    }
    const limits = [
        {
            name: "size",
            value: inputedFilesSize,
            limit: 20 * 1024 * 1024, //20 MB in bytes (binary)
            errorText: "The maximum total upload size is 20 MB."
        },
        {
            name: "count",
            value: inputtedFiles.length,
            limit: 20,
            errorText: "You can attach a maximum of 20 files."
        }
    ];

    for (let i = 0; i < limits.length; i++) {
        if (limits[i].value > limits[i].limit) {
            $inputLimit.append(`<div><i class="fa-solid fa-triangle-exclamation"></i> ${limits[i].errorText}</div>`)
            isError = true;
            inputAlertShow(true);
        }
    };
    
    if (isLargeFile) {
        $inputLimit.append(`<div><i class="fa-solid fa-triangle-exclamation"></i> The maximum individual file size is 1 MB.</div>`);
        isError = true;
        inputAlertShow(true);
    }

    if (!isError) {
        if (inputtedFiles.length > 0) {
            $convertButton.prop('disabled', false);
            showInputedFiles(inputtedFiles);
            $clearInputtedFilesBtn.removeClass('d-none');
            $inputtedFileWrapper.removeClass('d-none');
            $('.decoration').addClass('d-none');

        } else {
            clearInputtedFiles();
            $convertButton.prop('disabled', true);
        }
    }
});

$convertButton.on("click", function (e) { 
     convertFiles(inputtedFiles);
     convertInProgress();
});

$loginLink.on("click", function (e) {
    //TODO remove - redirect to page
    //showSignInPopup(signInPopupsData.signIn, reloadPage);
});

$clearInputtedFilesBtn.on("click", function (e) {
    clearInputtedFiles();
});

$(document).on("click", ".js-convert-new-files", function (e) {
    if (pendingPageRefreshAfterSuccessSignin) {
        reloadPage();
    }
    else {
     prepareConvertNewFiles();
    }
});

$(document).on("click", ".js-home-navigation", function (e) {
    prepareConvertNewFiles();
});

$(document).on("click", ".js-download-converted-files", function (e) {
    convertFiles(inputtedFiles)
});

closeCookiesModalBtn.addEventListener('click', function(e) {
    cookiesModal.style.display = 'none';
    setCookie("WalrusDenegeratorAllowCookies", 'true', 365);
}, false);

$accountButton.on("click", function (e) {
    e.stopPropagation();
    if ($accountMenu.hasClass('account-actions--collapsed')) {
        $accountMenu.removeClass('account-actions--collapsed');
        accountMenuVisibility = false;
    } else {
        $accountMenu.addClass('account-actions--collapsed')
        accountMenuVisibility = true;
    }
});

$('.navigation__collaps-button').on("click", function (e) {

    if ($('.navigation__menu').hasClass('navigation--visible')) {
        $('.icon-first').removeClass('d-none');
        $('.icon-second').addClass('d-none');
        $('.navigation__menu').removeClass('navigation--visible');
    } else {
        $('.icon-first').addClass('d-none');
        $('.icon-second').removeClass('d-none');
        $('.navigation__menu').addClass('navigation--visible');
    }
});

document.getElementById("user-info-form").addEventListener('keydown', (event) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
        event.preventDefault();
        const button = document.querySelector('.js-signin-button');
        button.click();
    }
});

$(document).click(function (e) {

    if (!$(e.target).is($accountButton) && !$(e.target).is($accountMenu)) {
        $accountMenu.addClass('account-actions--collapsed');
    } else {
        //
    }
});
 
//Functions

function toggleActionsMenuVisibility() {
    if ($accountMenu.hasClass('account-actions--collapsed')) {
        $accountMenu.removeClass('account-actions--collapsed');
        accountMenuVisibility = false;
    } else {
        $accountMenu.addClass('account-actions--collapsed')
        accountMenuVisibility = true;
    }
}
function renderCopyrightDate() {
    const currentYear = new Date().getFullYear();
    $('.js-copyright-years').html('2023 − ' + currentYear);
}
function okClickSingIn(containerSelector, onSuccess) {
    $("#errors-message").text("");
    var form = document.querySelector('.js-user-info-form')

    let isValid = true
     
    if (form) {
        form.classList.add('was-validated');

        if (form.checkValidity() === false) {
            isValid = false;
        }
    }

    if (form.querySelector('.is-invalid')) {
        isValid = false;
    }

    if (isValid) {

        var loginUrl = $("#RememberMe").is(":checked") ? "/login?useCookies=true" : "/login?useSessionCookies=true";
        disableControls(containerSelector);
        $.ajax({
            url: loginUrl,
            data: JSON.stringify({
                email: $("#Email").val(),
                password: $("#Password").val(),
            }),
            processData: false,
            contentType: "application/json",

            type: "POST",
            success: function (data) {
                if (typeof (onSuccess) == "function") { onSuccess(data); } 
            },
            error: function (xhr) {

                if (xhr.responseJSON && xhr.responseJSON.status == 401) {
                    var message = "Invalid Log In attempt. ";// + (xhr.responseJSON.detail || "");
                }
                else {
                    message = "Failed. An error has occured";// + ((xhr.responseJSON || {}).detail || "");
                }
                $("#errors-message").text(message);
            },
            complete: function () {
                enableControls(containerSelector);
            }
        });
    }
}

function onSuccessSignInOnConvert() {
    $('#signInModal').modal('hide');
    convertInProgress();
    convertFiles(inputtedFiles);
}

function reloadPage() {
    pendingPageRefreshAfterSuccessSignin = false; // not needed as we do refresh but put here to show the logic 
    setTimeout(()=>location.reload(), 100);
}

function disableControls(containerSelector) {
    $(containerSelector).find("button").prop("disabled", true);
    $(containerSelector).find("input").prop("disabled", true);
}

function enableControls(containerSelector) {
    $(containerSelector).find("button").prop("disabled", false);
    $(containerSelector).find("input").prop("disabled", false);
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function checkCookie() {
  var cookiesAllowed = getCookie("WalrusDenegeratorAllowCookies");

  if (cookiesAllowed == "") {
    cookiesModal.style.display = 'block';
  }
}

function showSignInPopup(popupData, onSuccessFunc) {
    pendingPageRefreshAfterSuccessSignin = true;
    configureSignIn("#signInModal", popupData, onSuccessFunc);
    $('#signInModal').modal('show');
}

function configureSignIn(containerSelector, popupData, onSuccessFunc) {
    $(containerSelector + ' #errors-message').text("");
    $(containerSelector + ' .js-user-info-form').removeClass('was-validated');
    $(containerSelector + ' .js-user-info-form')[0].reset();

    $(containerSelector + ' #modal-title-1').text(popupData.ModalTitle1);
    $(containerSelector + ' #modal-title-2').text(popupData.ModalTitle2);

    $(containerSelector + ' #ok-button').text(popupData.OkButtonText);
    $(containerSelector + ' #ok-button-i').removeClass(); $(containerSelector + ' #ok-button-i').addClass(popupData.OkButtonIClass);

    $(containerSelector + ' #email-text-before-1').text(popupData.EmailTextBefore1);
    $(containerSelector + ' #email-text-before-2').text(popupData.EmailTextBefore2);
    $(containerSelector + ' #email-text-after-1').text(popupData.EmailTextAfter1);
    $(containerSelector + ' #email-text-after-2').text(popupData.EmailTextAfter2);
 
    $(containerSelector + ' .js-signin-button').off();
    $(containerSelector + ' .js-signin-button').on('click', () => okClickSingIn(containerSelector, onSuccessFunc));
}

function prepareConvertNewFiles() {
    inputtedFiles = '';
    $fileInput.val('');
    renderConvertNewSection();
}
function showInputedFiles(files) {

    let renderFiles;
    let temp;

    for (let i = 0; i < files.length; i++) {
        let file = files[i];
        temp = `<li><i class="fa-solid fa-file-arrow-up"></i> ${file.name}</li>`;

        if (i === 0) {
            renderFiles = temp;
        } else {
            renderFiles += temp
        }

    }

    $inputtedFilesPlacement.html(renderFiles);

};

function hideInputedFiles() {
    $inputtedFilesPlacement.html('');
};

function clearInputtedFiles() {
    $convertButton.prop('disabled', true);
    inputtedFiles = '';
    $fileInput.val('');

    $inputtedFileWrapper.addClass('d-none');
    $('.decoration').removeClass('d-none');
};

function blockFileInput(clearFiles) {
    if (clearFiles) {
        clearInputtedFiles();
    }
    $fileInput.prop('disabled', true);
};

function unblockFileInput() {
    $fileInput.prop('disabled', false);
}

function inputAlertShow(key) {
    if (key) {
        $inputLimit.removeClass('d-none');
    } else {
        $inputLimit.addClass('d-none');
        $inputLimit.text('');
    }
}

function convertFiles(files) {
 
    if (files.length > 0) {
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
        }

        converterResponse = $.ajax({
            url: "/api/FileConverter/ConvertFiles",
            data: formData,
            processData: false,
            contentType: false,
            xhrFields: {
                responseType: 'blob'
            },
            type: "POST",
            success: function (data) {
                renderDownloadSection();
                handleFileDownload(data);
                convertNotInProgress();

                if (pendingPageRefreshAfterSuccessSignin) {
                    reloadPage();
                }                
            },
            error: function (xhr) {                 
                if (xhr.status == 401) {
                    undoConvertInProgress();
                    showSignInPopup(signInPopupsData.signInOnConvert, onSuccessSignInOnConvert);
                }
                else {
                    renderErrorSection(); // when after success signin the error section's convert new will do a page reload  
                    convertNotInProgress();
                     
                }                 
            }            
        });
    }
}

function handleFileDownload(data) {
    let blob = new Blob([data], { type: data.type });
    let filename = "";
    
    let disposition = converterResponse.getResponseHeader('content-disposition');

    if (disposition && disposition.indexOf('attachment') !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
    }
    if (navigator.msSaveBlob) {
        // For Internet Explorer
        navigator.msSaveBlob(blob, filename);
    } else {
        // For other browsers
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

function convertInProgress() {
    $clearInputtedFilesBtn.addClass('d-none');
    $convertButton.prop('disabled', true);
    $('.convert-spinner').removeClass('d-none');
    $fileWrapper.addClass('convert-in-progress');
    $fileInput.prop('disabled', true);
}

function undoConvertInProgress() {
    $clearInputtedFilesBtn.removeClass('d-none');
    $convertButton.prop('disabled', false);
    $('.convert-spinner').addClass('d-none');
    $fileWrapper.removeClass('convert-in-progress');
    $fileInput.prop('disabled', false);
}

function convertNotInProgress() {
    $fileWrapper.removeClass('convert-in-progress');
    $('.convert-spinner').addClass('d-none');
    $inputtedFileWrapper.addClass('d-none');
    hideInputedFiles();
    $('.decoration').removeClass('d-none');
}

function renderDownloadSection() {
    blockFileInput(false);
    $fileWrapper.addClass('convert-succsessful');
    $('.description .message').text(`Download is started!`);
    $('.description .download').removeClass(`d-none`);
    $('.upload-icon').removeClass('fa-file-arrow-up fa-7x');
    $('.upload-icon').addClass('fa-circle-check fa-3x');

    $convertButton.addClass('d-none');
    $('.js-download-converted-files').removeClass('d-none');
    $('.js-convert-new-files').removeClass('d-none');

    $('.js-start-page').addClass('d-none');
    $('.js-final-page').removeClass('d-none');
}

function renderErrorSection() {
    blockFileInput(true);
    $fileWrapper.addClass('convert-error');
    $('.description .message').html(`Something went wrong<br>Please try again`);
    $('.upload-icon').removeClass('fa-file-arrow-up fa-7x');
    $('.upload-icon').addClass('fa-circle-exclamation fa-3x');
    $convertButton.addClass('d-none');
    $('.js-convert-new-files').removeClass('d-none');
}

function renderConvertNewSection() {
    unblockFileInput();
    $fileInput.prop('disabled', false);
    $fileWrapper.removeClass('convert-succsessful convert-error');
    $('.description span').text(`Select files`);
    $('.description .download').addClass(`d-none`);
    $('.upload-icon').removeClass('fa-circle-check fa-circle-exclamation fa-3x');
    $('.upload-icon').addClass('fa-file-arrow-up fa-7x');
    
    $('.js-download-converted-files').addClass('d-none');
    $('.js-convert-new-files').addClass('d-none');
    $convertButton.removeClass('d-none');

    $('.js-final-page').addClass('d-none');
    $('.js-start-page').removeClass('d-none');
    
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function renderNavigationButtons() {
    const $navigation = $('.navigation');
    const navigationButtons = $navigation.find('.navigation__item');
    

    for (i = 0; i < navigationButtons.length; i++) {
        let currentButton = navigationButtons[i];
        let $linkEl = $(currentButton).find('a');

        if ($linkEl.hasClass('active')) {
            $(currentButton).addClass('navigation__item--selected');
        } else {
            $(currentButton).removeClass('navigation__item--selected');
        }
        

    }
} 

renderCopyrightDate();
checkCookie();
renderNavigationButtons();
